var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile"},[_c('header',{attrs:{"id":"wx-header"}},[_c('div',{staticClass:"center"},[_c('router-link',{staticClass:"iconfont icon-return-arrow",attrs:{"to":"/self","tag":"div"}},[_c('span',[_vm._v("我")])]),_c('span',[_vm._v("个人信息")])],1)]),_c('div',{staticClass:"weui-cells"},[_c('div',{staticClass:"weui-cell",attrs:{"id":"avatarCell"}},[_vm._m(0),_c('div',{staticClass:"weui-cell__ft"},[_c('img',{staticStyle:{"width":"50px","height":"50px","border-radius":"4px"},attrs:{"src":_vm.userInfo.avatar}})])]),_c('div',{staticClass:"weui-cell"},[_vm._m(1),_c('div',{staticClass:"weui-cell__ft"},[_vm._v(" "+_vm._s(_vm.userInfo.nickname)+" ")])]),_c('div',{staticClass:"weui-cell"},[_vm._m(2),_c('div',{staticClass:"weui-cell__ft"},[_vm._v(" "+_vm._s(_vm.userInfo.wxcode)+" ")])]),_c('router-link',{staticClass:"weui-cell weui-cell_access",attrs:{"to":"/self/profile/my-qrcode"}},[_c('div',{staticClass:"weui-cell__bd"},[_c('p',[_vm._v("我的二维码")])]),_c('div',{staticClass:"weui-cell__ft"},[_c('img',{staticClass:"_align-middle",staticStyle:{"vertical-align":"middle","width":"24px"},attrs:{"src":"/images/contact_add-friend-my-qr.png"}})])]),_vm._m(3)],1),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"weui-cell__bd"},[_c('p',[_vm._v("头像")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"weui-cell__bd"},[_c('p',[_vm._v("名字")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"weui-cell__bd"},[_c('p',[_vm._v("微信号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"weui-cell"},[_c('div',{staticClass:"weui-cell__bd"},[_c('p',[_vm._v("我的地址")])]),_c('div',{staticClass:"weui-cell__ft"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"weui-cells"},[_c('div',{staticClass:"weui-cell"},[_c('div',{staticClass:"weui-cell__bd"},[_c('p',[_vm._v("性别")])]),_c('div',{staticClass:"weui-cell__ft"},[_vm._v(" 男 ")])]),_c('div',{staticClass:"weui-cell"},[_c('div',{staticClass:"weui-cell__bd"},[_c('p',[_vm._v("地区")])]),_c('div',{staticClass:"weui-cell__ft"},[_vm._v(" 奥地利 维也纳 ")])]),_c('div',{staticClass:"weui-cell"},[_c('div',{staticClass:"weui-cell__bd"},[_c('p',[_vm._v("个性签名")])]),_c('div',{staticClass:"weui-cell__ft"},[_vm._v(" 未填写 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"weui-cells"},[_c('div',{staticClass:"weui-cell"},[_c('div',{staticClass:"weui-cell__bd"},[_c('p',[_vm._v("LinkedIn帐号")])]),_c('div',{staticClass:"weui-cell__ft"},[_vm._v(" 未设置 ")])])])
}]

export { render, staticRenderFns }